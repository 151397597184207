import dayjs from 'dayjs'
import IQueries from './index'
import GatewayBase from '../gatewayBase'

export default class QueriesOraStored extends IQueries {
    
    constructor(gateway) {
        super()
        /** @type GatewayBase */
        this.gateway = gateway
    }

    async queryRaw (queryName, params = {}, count=1, offset=0, options={}) {
        let result = await this.gateway.serverHttp("POST",'ora-alias-queries/exec',{
            queryName,
            params,
            count,
            offset
        })
        result = result.results
        if (options.convertDate) {
            for (let paramI in result) {
              const p = result[paramI]
              if (p.type !== 'CURSOR') continue
              for (let coln in p.value.columns) {
                const colType = p.value.columns[coln][1]
                if (colType === 'DATE') {
                  for (let i in p.value.list) {
                    const row = p.value.list[i]
                    result[paramI].value.list[i][coln] = 
                        (!!row[coln])? dayjs(row[coln]).format('DD.MM.YYYY HH:mm:ss'): null
                  }                
                }
              }
            }
        }
        return result
    }

}