import IQueries from '../queries'
import GatewayBase from '../gatewayBase'

export default class ApiDocuments {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    //By kind
    async getKindInfo(dockindName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/get-kind-info", {dockindName})        
        } else {
            return await this.carabi.selectRow('BR_CARABI_DOCKIND_INFO_3_CS', { dockind_name: dockindName });        
        }
    }

    async listStatusColors(dockindName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/list-status-colors", { dockindName })
        } else {
            return await this.carabi.select('BR_GET_STATUS_COLOR_CS', { DOCKIND_NAME: dockindName }, 1000)
        }
    }

    //Stages
    async listStages(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/list-stages", { documentId })
        } else {
            return await this.carabi.select("GET_DOC_BP_STAGES_CS", { DOC_ID: documentId }, 1000)                
        }
    }

    //Actions

    //Field Getters
    async getValues(documentId, propName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/get-values", {
                documentId, propName
            })
        } else {
            return await this.carabi.select( "GET_VALUES_CS", {
                DOCUMENT_ID : documentId, //number
                FIELD_NAME : propName //varchar
            }, 1000)
        }
    }

    async getValue(documentId, propName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/get-value", {
                documentId, propName
            })
        } else {
            return await this.carabi.selectRow('CRM_GET_VALUE_CS',{
                "DOCUMENT_ID" : documentId, //varchar
                "FIELD_NAME": propName //varchar
            });
        }
    }

    async getRefsByDocprop(documentId, docPropName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/get-refs-by-docprop", {
                docKind, documentId, docPropName
            })
        } else {
            return await this.carabi.select("CRM_GET_REF_BY_DOCPROP_NAME",{
                DOCUMENT_ID : documentId,
                DOCPROP_NAME : docPropName
            }, 1000)
        }
    }

    async getPropDisplay(documentId, propName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/get-prop-display", {
                documentId, propName
            })
        } else {
            return await this.carabi.query( "CRM_GET_PROP_DISPLAY_CS", {
                DOCUMENT_ID : documentId, //number
                FIELD_NAME : propName //varchar
            })
        }
    }

    async getDocFieldPermission(documentId, propName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/get-doc-field-permission", {
                documentId, propName
            })
        } else {
            return await this.carabi.selectRow('CARD_DOC_FIELD_PERMISSION',{
                "DOCUMENT_ID" : documentId, //number
                "PROP_NAME" : propName //varchar
            })
        }
    }

    //History
    async listHistoryStatuses(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/list-history-statuses", { documentId })            
        } else {
            return await this.carabi.select( "CARD_GET_HISTORY_LIST_CS", { DOC_ID : documentId }, 100000)
        }
    }

    async listHistoryValues(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/list-history-values", { documentId })
        } else {
            return await this.carabi.select( "CARD_GET_HISTORY_VALUE_LIST_CS", { DOC_ID : documentId }, 100000)
        }
    }

    //Lists
    async listProperties(documentId) {
        //Используется в истории
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/list-properties", { documentId })
        } else {
            return await this.carabi.select( "CARD_DOC_PROPERTIES", { DOCUMENT_ID : documentId }, 100000)
        }
    }

    async listPropertiesValues(documentId) {
        //Используется в плагине main
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/list-properties-values", { documentId })
        } else {
            return await this.carabi.select("CARD_PROPERTIES",{ "DOCUMENT_ID" : documentId },1000)
        }
    } 

    //Create
    async create(dockindName, classifPropId, classifId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/create", {
                dockindName, classifPropId, classifId
            })
        } else {
            let result = await this.carabi.query( "CRM_DOCUMENT_CREATE_CS", {
                RES_NAME : classifId, //varchar
                RES_PROP_ID : classifPropId, //number
                DOCKIND : dockindName //varchar
            })
            return result.CUR[0]
        }
    }

    async clone(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/clone", {
                documentId
            })
        } else {
            let result = await this.carabi.query('CRM_CREATE_COPY_CS',{
                DOCUMENT_ID: documentId
            })
            return result
        }
    }

    async createRefByDocprop(documentId, docPropName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/create-ref-by-docprop", {
                documentId, docPropName
            })
        } else {
            let result = await this.carabi.query( "CRM_DOCUMENT_REF_CREATE_CS", {
                DOCUMENT_ID : documentId, //number
                DOCPROP_NAME : docPropName //varchar
            },1);
            return result.RESULT[0]
        }
    }

    async createChildDocument(parentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/create-child-document", {
                parentId
            })
        } else {
            return await this.carabi.query( "BR_CARABI_CREATE_CHILD_DOCUMENT_CS", {
                parent_id : parentId //number
            })
        }
    }

    //Delete
    async checkDeletable(documentIds) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/check-deletable", {
                dockindName
            })
        } else {
            let result = await this.carabi.query("CRM_CAN_DELETE_FDOCS_CS",{
                "DOC_LIST" : documentIds.join(",") //varchar
                //"PARENT_PROP_ID" : this.docPropId, //number
                //"PARENT_DOC_ID" : this.documentId //number
            });
            return !!parseInt(result.RESULT);    
        }
    }

    async delete(documentIds) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents/delete", { documentIds })
        } else {
            await this.carabi.query("DELETE_DOCUMENTS_CS",{ doc_list: documentIds.join(',') });        
        }
    }
}